$sides: (
  t: top,
  b: bottom,
  l: left,
  r: right
);

$spacer-max: 24;
$spacer-size: 10px;
// New Styles are in ~4's, this allows us to do it easily.
$new-spacer-size: 4px;

@for $i from 0 through $spacer-max {
  .m#{$i} {
    margin: $i * $spacer-size;
  }
  .p#{$i} {
    padding: $i * $spacer-size;
  }
  .pad#{$i} {
    padding: $i * $new-spacer-size;
  }

  .mar#{$i} {
    margin: $i * $new-spacer-size;
  }
}

@for $i from 0 through $spacer-max {
  @each $side-name, $side-direction in $sides {
    .m#{$side-name}#{$i} {
      margin-#{$side-direction}: $i * $spacer-size;
    }

    .p#{$side-name}#{$i} {
      padding-#{$side-direction}: $i * $spacer-size;
    }
    .pad#{$side-name}#{$i} {
      padding-#{$side-direction}: $i * $new-spacer-size;
    }

    .mar#{$side-name}#{$i} {
      margin-#{$side-direction}: $i * $new-spacer-size;
    }
  }
}

@for $i from 0 through $spacer-max {
  @each $breakpoint-name, $breakpoint-size in $breakpoints {
    @media (min-width: #{$breakpoint-size}) {
      .m#{$i}--#{$breakpoint-name} {
        margin: $i * $spacer-size;
      }
      .p#{$i}--#{$breakpoint-name} {
        padding: $i * $spacer-size;
      }
      .pad#{$i}--#{$breakpoint-name} {
        padding: $i * $new-spacer-size;
      }

      .mar#{$i}--#{$breakpoint-name} {
        margin: $i * $new-spacer-size;
      }
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @for $i from 0 through $spacer-max {
      @each $side-name, $side-direction in $sides {
        .m#{$side-name}#{$i}--#{$breakpoint-name} {
          margin-#{$side-direction}: $i * $spacer-size;
        }

        .p#{$side-name}#{$i}--#{$breakpoint-name} {
          padding-#{$side-direction}: $i * $spacer-size;
        }

        .pad#{$side-name}#{$i}--#{$breakpoint-name} {
          padding-#{$side-direction}: $i * $new-spacer-size;
        }

        .mar#{$side-name}#{$i}--#{$breakpoint-name} {
          margin-#{$side-direction}: $i * $new-spacer-size;
        }
      }
    }
  }
}
