/*
// === Reset ===
*/
// "cool hack" by Josh. This works because this is *imported* within the
// `.app-container`, and should ultimatly be removed.
&,
body {
  @include font-smoothing;
  @include body-font-size;
  font-family: $font-family-freight;
  font-weight: 400;
  color: color(blue);
}

//
// === Utilities ===
//

.capitalize {
  text-transform: capitalize;
  letter-spacing: 0.05em;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

// new uppercase with no letter spacing, to replace old uppercase
.uppercase-new {
  text-transform: uppercase;
}

.line-through {
  text-decoration: line-through;
}

.text--italic {
  font-style: italic;
}

.text--bold {
  font-weight: bold;
}

.text--hidden {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

//
// === Styles ===
//

@mixin fg-condensed--body {
  @include body-font-size;
  font-family: $font-family-franklin-condensed;
}

@mixin fg-condensed--xlarge {
  font-size: 48px;
  line-height: 44px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg-condensed--large {
  font-size: 40px;
  line-height: 40px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg-condensed--medium {
  font-size: 32px;
  line-height: 34px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg--medium {
  font-size: 32px;
  line-height: 34px;
  font-family: $font-family-franklin;
}

@mixin fg-condensed--small {
  font-size: 26px;
  line-height: 28px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg--small {
  font-size: 26px;
  line-height: 28px;
  font-family: $font-family-franklin;
}

@mixin fg-condensed--xsmall {
  font-size: 18px;
  line-height: 20px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg--xsmall {
  font-size: 18px;
  line-height: 20px;
  font-family: $font-family-franklin;
}

@mixin fg-condensed--xxsmall {
  font-size: 14px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg--xxsmall {
  font-size: 14px;
  line-height: 16px;
  font-family: $font-family-franklin;
}

@mixin fg-condensed--xxxsmall {
  font-size: 12px;
  line-height: 14px;
  font-family: $font-family-franklin-condensed;
}

@mixin fg--xxxsmall {
  font-size: 12px;
  line-height: 14px;
  font-family: $font-family-franklin;
}

@mixin freight--body {
  @include body-font-size;
  font-family: $font-family-freight;
}

@mixin freight--large {
  font-family: $font-family-freight;
  font-size: 18px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 23px;
  }
}

@mixin freight--xlarge {
  font-family: $font-family-freight;
  font-size: 30px;
  line-height: 32px;
}

.text--fg {
  font-family: $font-family-franklin;
}

.fg-condensed--body {
  @include fg-condensed--body;
}

.fg-condensed--xlarge {
  @include fg-condensed--xlarge;
}

.fg-condensed--large {
  @include fg-condensed--large;
}

.fg-condensed--medium {
  @include fg-condensed--medium;
}

.fg--medium {
  @include fg--medium;
}

.fg-condensed--small {
  @include fg-condensed--small;
}

.fg--small {
  @include fg--small;
}

.fg-condensed--xsmall {
  @include fg-condensed--xsmall;
}

.fg--xsmall {
  @include fg--xsmall;
}

.fg-condensed--xxsmall {
  @include fg-condensed--xxsmall;
}

.fg--xxsmall {
  @include fg--xxsmall;
}

.fg-condensed--xxxsmall {
  @include fg-condensed--xxxsmall;
}

.fg--xxxsmall {
  @include fg--xxxsmall;
}

.freight--body {
  @include freight--body;
}

.freight--large {
  @include freight--large;
}

.freight--xlarge {
  @include freight--xlarge;
}

@mixin holiday--xlarge {
  font-size: 48px;
  line-height: 44px;
  font-family: $font-family-holiday;
}

@mixin holiday--large {
  font-size: 40px;
  line-height: 48px;
  font-family: $font-family-holiday;
}

@mixin holiday--medium {
  font-size: 32px;
  line-height: 34px;
  font-family: $font-family-holiday;
}
@mixin holiday--small {
  font-size: 26px;
  line-height: 28px;
  font-family: $font-family-holiday;
}

@mixin holiday--xsmall {
  font-size: 18px;
  line-height: 24px;
  font-family: $font-family-holiday;
}

.holiday--xlarge {
  @include holiday--xlarge;
}

.holiday--large {
  @include holiday--large;
}

.holiday--medium {
  @include holiday--medium;
}

.holiday--small {
  @include holiday--small;
}

.holiday--xsmall {
  @include holiday--xsmall;
}

//
// === 3.0 Typography ===
//  Last Updated 06/2021
//
.block__eyebrow--title,
.block__eyebrow--title--md,
.block__eyebrow--title--sm,
.block__title,
.block__title--md,
.block__header,
.block__subheader,
.block__subheader--md,
.block__subheader--sm,
.block__subheader--sm--desktop {
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
}

.block__eyebrow--title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1em;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.block__eyebrow--title--md {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.block__eyebrow--title--sm {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: color(gray-text-light);
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.block__title {
  font-size: 40px;
  line-height: 44px;
  @media screen and (max-width: 1000px) {
    font-size: 32px;
    line-height: 36px;
  }
}

.block__title--md {
  font-size: 32px;
  line-height: 36px;
  @media screen and (max-width: 1000px) {
    font-size: 28px;
    line-height: 32px;
  }
}

.block__subtitle {
  font-size: 28px;
  line-height: 32px;
  @media screen and (max-width: 1000px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.block__header {
  font-size: 24px;
  line-height: 32px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.block__subheader {
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.block__subheader--md {
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 16px;
  }
}
.block__subheader--sm--desktop {
  font-size: 14px;
  line-height: 16px;
}

.block__subheader--sm {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.block__body--lg {
  font-family: $font-family-freight;
  font-size: 24px;
  line-height: 32px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.block__body {
  font-family: $font-family-freight;
  font-size: 18px;
  line-height: 32px;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.block__copy {
  font-family: $font-family-freight;
  font-size: 16px;
  line-height: 24px;
}

.block__heading--sm {
  font-family: $font-family-franklin;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.05em;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 18px;
  }
}
.block__standard-subtitle {
  font-family: $font-family-franklin;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    line-height: 14px;
  }
}
.block__standard-subtitle--desktop {
  font-family: $font-family-franklin;
  font-size: 14px;
  line-height: 16px;
}

.color--faded {
  opacity: 0.7;
}

.color--faded--link {
  opacity: 0.7;
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--body--#{$breakpoint-name} {
      @include fg-condensed--body;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--xlarge--#{$breakpoint-name} {
      @include fg-condensed--xlarge;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--large--#{$breakpoint-name} {
      @include fg-condensed--large;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--medium--#{$breakpoint-name} {
      @include fg-condensed--medium;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg--medium--#{$breakpoint-name} {
      @include fg--medium;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--small--#{$breakpoint-name} {
      @include fg-condensed--small;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg--small--#{$breakpoint-name} {
      @include fg--small;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--xsmall--#{$breakpoint-name} {
      @include fg-condensed--xsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg--xsmall--#{$breakpoint-name} {
      @include fg--xsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--xxsmall--#{$breakpoint-name} {
      @include fg-condensed--xxsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg--xxsmall--#{$breakpoint-name} {
      @include fg--xxsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg-condensed--xxxsmall--#{$breakpoint-name} {
      @include fg-condensed--xxxsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .fg--xxxsmall--#{$breakpoint-name} {
      @include fg--xxxsmall;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .freight--body--#{$breakpoint-name} {
      @include freight--body;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .freight--large--#{$breakpoint-name} {
      @include freight--large;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .freight--xlarge--#{$breakpoint-name} {
      @include freight--xlarge;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .holiday--xlarge--#{$breakpoint-name} {
      @include holiday--xlarge;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .holiday--large--#{$breakpoint-name} {
      @include holiday--large;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .holiday--medium--#{$breakpoint-name} {
      @include holiday--medium;
    }
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .holiday--small--#{$breakpoint-name} {
      @include holiday--small;
    }
  }
}
//
// === Scale ===
// This is inspired by Tachyons, which most of Bondfire's functional
// CSS also seems to be inspired by.
//

.f-headline {
  font-size: 48px;
}

.f-subheadline {
  font-size: 40px;
}

.f1 {
  font-size: 32px;
}

.f2 {
  font-size: 26px;
}

.f3 {
  font-size: 18px;
}

.f4 {
  font-size: 16px;
}

.f5 {
  font-size: 14px;
}

.f6 {
  font-size: 12px;
}

//
// === Links ===
//

.link--nudge {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    border-bottom: 1px solid;
    pointer-events: none;
    transition: transform 100ms ease-in-out;
  }

  &:hover:after {
    transform: translateY(2px);
  }
}

.link--underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link--hover:hover {
  text-decoration: underline;
}

.link--target-hover:hover .link__target {
  text-decoration: underline;
}

.link--opacity {
  opacity: 1;
  transition: opacity 50ms ease-out;

  &:hover {
    opacity: 0.5;
  }
}
