@use 'sass:math';

@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin multiLineEllipsis($line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  max-height: $line-height * $lines-to-show; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin selection {
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin scrollbar(
  $scrollbar-height: 5px,
  $scrollbar-padding: 0px,
  $thumb-color: black,
  $track-color: gray
) {
  &::-webkit-scrollbar {
    height: (($scrollbar-padding * 2) + $scrollbar-height);
    background-color: rgba(255, 255, 255, 0);
    cursor: move;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border: $scrollbar-padding solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border: $scrollbar-padding solid rgba(255, 255, 255, 0);
  }
}

@mixin aspect-ratio($width: 1, $height: 1) {
  height: 0;
  padding-bottom: math.div($height, $width) * 100%;
}

@mixin body-font-size {
  font-size: $font-size-body-mobile;
  line-height: $line-height-body-mobile;

  @include breakpoint(lg) {
    font-size: $font-size-body-desktop;
    line-height: $line-height-body-desktop;
  }
}

@mixin shimmer-animation($color, $delay: 0s) {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($color, 0) 0,
      rgba($color, 0.2) 20%,
      rgba($color, 0.5) 60%,
      rgba($color, 0)
    );
    animation: shimmer 1.5s infinite $delay;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

@mixin product-tile-brand {
  white-space: initial;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  font-family: $font-family-franklin;
  opacity: 0.7;
  @include breakpoint-max(lg) {
    font-size: 12px;
    line-height: 14px;
  }
  &:hover {
    color: color(blue);
    cursor: pointer;
  }
}
