.deal-tag {
  height: 24px;
  line-height: 24px;
  background: color(white);
  color: color(blue);
  font-size: 12px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 10px;

  @include breakpoint(md) {
    font-size: 16px;
  }
}
