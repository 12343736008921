// used for determining positioning and size of the border-bottom on a link
@mixin link-hover-underline(
  $padding: 16px,
  $button: false,
  $underlineColor: color(yellow),
  $size: 2px,
  $defaultPosition: -4px
) {
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: $size solid;
    pointer-events: none;
    transition: transform 100ms ease-in-out;

    @if underlineColor == inherit {
      color: inherit;
    } @else {
      color: $underlineColor;
    }

    @if $button {
      bottom: calc($padding + 2px);
      width: calc(100% - ($padding * 2));
      left: 50%;
      transform: translateX(-50%);
    } @else {
      left: 0;
      right: 0;
      bottom: $defaultPosition;
      width: auto;
    }
  }
}

%primary-link-preset {
  @extend %primary-button-and-link-preset;
  position: relative;
}

.primary-link-preset-1 {
  @extend %primary-link-preset;
  @extend .color-primary;
  @include link-hover-underline(0px);

  &:hover:after {
    transform: translateY(4px);
  }
}

.primary-link-preset-2 {
  @extend %primary-link-preset;
  @extend .bg-color-primary;
  @extend .color-secondary;
  @include link-hover-underline($button-padding, true);
  padding: $button-padding;
  padding-bottom: $button-padding + 6px; // to accomodate extra space taken by the border-bottom (space between the text and border is 6px)

  &:hover:after {
    transform: translateY(4px) translateX(-50%);
  }
}

.primary-link-preset-3 {
  @extend .font-primary;
  @extend .color-primary;
  @extend .primary-text-preset-8;
  @include link-hover-underline(0px, false, color(black), 1px, 0);
  position: relative;
  cursor: pointer;
  font-weight: 400 !important;

  &:hover:after {
    transform: translateY(2px);
  }
}

// a preset that inherits the font from parent, usable for inline links
.primary-link-preset-4 {
  @include link-hover-underline(0px, false, inherit, 1px, 0);
  position: relative;
  cursor: pointer;

  &:hover:after {
    transform: translateY(2px);
  }
}
