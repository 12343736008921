@font-face {
  font-family: 'Huckberry Symbols';
  src: url('~assets/fonts/Huckberry-Symbols.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Freight Text';
  src: url('~assets/fonts/Freight-Medium.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('~assets/fonts/Franklin-Medium.woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('~assets/fonts/Franklin-Demi.woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic Condensed';
  src: url('~assets/fonts/Franklin-Condensed-Book.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic Condensed';
  src: url('~assets/fonts/Franklin-Condensed-Demi.woff');
  font-weight: 600;
  font-style: normal;
}

/* https://fonts.adobe.com/fonts/duper#fonts-section 
   Licensed for Commercial Projects
   Designed by Martin Wenzel. From supertype.
*/
@font-face {
  font-family: 'duper';
  src: url('~assets/fonts/Duper.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Holiday Font';
  src: url('~assets/fonts/Heritage-Sans.woff2');
  font-weight: 500;
  font-style: normal;
}
