.list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  line-height: 0;
}

.list--scroll {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  .list__item {
    white-space: initial;
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    opacity: 0;
  }

  padding-bottom: 20px;

  &.list--scroll--grid-reset {
    margin-left: -$grid-padding-mobile;
    margin-right: -$grid-padding-mobile;
    padding-left: $grid-padding-mobile;
    padding-right: $grid-padding-mobile;

    @include breakpoint(sm) {
      margin-left: -$grid-padding-tablet;
      margin-right: -$grid-padding-tablet;
      padding-left: $grid-padding-tablet;
      padding-right: $grid-padding-tablet;
    }

    @include breakpoint(lg) {
      margin-left: -$grid-padding-desktop;
      margin-right: -$grid-padding-desktop;
      padding-left: $grid-padding-desktop;
      padding-right: $grid-padding-desktop;
    }
  }
}

.list__item {
  @include body-font-size;
}

.containing-element {
  display: inline-block;
  padding: 0px 4px 20px 4px;
}

// Inline
.list--horizontal {
  > .list__item {
    display: inline-block;
    margin-right: $grid-gutter-desktop;

    &:last-child {
      margin-right: 0;
    }
  }

  &.list--vertical-gutter {
    margin-bottom: -$grid-gutter-desktop;
    > .list__item {
      margin-bottom: $grid-gutter-desktop;
    }
  }
}

// Block List
.list--vertical {
  > .list__item {
    display: block;
    margin-bottom: $grid-gutter-desktop;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Vertical Align
.list.align--middle {
  > .list__item {
    vertical-align: middle;
  }
}

// Extra Gutter Sizes
@each $gutter-name, $gutter-size in $grid-gutter-extra {
  .list--vertical.gutter--#{$gutter-name} {
    > .list__item {
      margin-bottom: $gutter-size;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list--horizontal.gutter--#{$gutter-name} {
    > .list__item {
      margin-right: $gutter-size;

      &:last-child {
        margin-right: 0;
      }
    }

    &.list--vertical-gutter {
      margin-bottom: -$gutter-size;
      > .list__item {
        margin-bottom: $gutter-size;
      }
    }
  }
}

// Responsive Orientation
@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .list--horizontal--#{$breakpoint-name} {
      > .list__item {
        display: inline-block;
        margin-right: $grid-gutter-desktop;

        &:last-child {
          margin-right: 0;
        }
      }

      &.list--vertical-gutter {
        margin-bottom: -$grid-gutter-desktop;

        > .list__item {
          margin-bottom: $grid-gutter-desktop;
        }
      }
    }

    // Block List
    .list--vertical--#{$breakpoint-name} {
      > .list__item {
        display: block;
        margin-bottom: $grid-gutter-desktop;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Responsive Gutters
@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @each $gutter-name, $gutter-size in $grid-gutter-extra {
      .list--vertical.gutter--#{$gutter-name}--#{$breakpoint-name},
      .list--vertical--#{$breakpoint-name}.gutter--#{$gutter-name}--#{$breakpoint-name},
      .list--vertical--#{$breakpoint-name}.gutter--#{$gutter-name} {
        > .list__item {
          margin-bottom: $gutter-size;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .list--horizontal.gutter--#{$gutter-name}--#{$breakpoint-name},
      .list--horizontal--#{$breakpoint-name}.gutter--#{$gutter-name}--#{$breakpoint-name},
      .list--horizontal--#{$breakpoint-name}.gutter--#{$gutter-name} {
        > .list__item {
          margin-right: $gutter-size;

          &:last-child {
            margin-right: 0;
          }
        }

        &.list--vertical-gutter {
          margin-bottom: -$gutter-size;

          > .list__item {
            margin-bottom: $gutter-size;
          }
        }
      }
    }
  }
}
