@import '~styles/global/config';

.app-container {
  .FilterDrawer__button--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .FilterDrawer__title {
    text-transform: uppercase;
    display: inherit;
    text-align: left;
    width: 100%;
    padding: 16px 0;
    @include breakpoint-max(lg) {
      padding: 24px 0;
    }
  }
  .FilterDrawer__icon {
    height: 8px;
    width: 12px;
    transition: transform 200ms linear;
  }
  .FilterDrawer__icon--open {
    transform: rotate(180deg);
  }
  .FilterDrawer__values {
    display: none;
  }
  .FilterDrawer__values--open {
    display: block;
  }
}
