@use 'sass:math';

html,
body {
  overflow-x: hidden;
}

.row {
  margin-left: math.div($grid-gutter-mobile, -2);
  margin-right: math.div($grid-gutter-mobile, -2);
  list-style: none;
  min-height: 1px;
  vertical-align: top;
  font-size: 0;
  line-height: 0;

  @include breakpoint(sm) {
    margin-left: math.div($grid-gutter-desktop, -2);
    margin-right: math.div($grid-gutter-desktop, -2);
  }

  > .col {
    @include body-font-size;
    display: inline-block;
    width: 100%;
    padding-left: $grid-gutter-mobile * 0.5;
    padding-right: $grid-gutter-mobile * 0.5;
    box-sizing: inherit;
    vertical-align: top;

    @include breakpoint(sm) {
      padding-left: $grid-gutter-desktop * 0.5;
      padding-right: $grid-gutter-desktop * 0.5;
    }
  }
}

.row.gutter--vertical {
  margin-bottom: -$grid-gutter-mobile;

  > .col {
    padding-bottom: $grid-gutter-mobile;
  }

  @include breakpoint(sm) {
    margin-bottom: -$grid-gutter-desktop;

    > .col {
      padding-bottom: $grid-gutter-desktop;
    }
  }
}

@each $gutter-name, $gutter-size in $grid-gutter-extra {
  .row.gutter--#{$gutter-name} {
    margin-left: math.div($gutter-size, -2);
    margin-right: math.div($gutter-size, -2);

    > .col {
      padding-left: $gutter-size * 0.5;
      padding-right: $gutter-size * 0.5;
    }
  }
  .row.gutter--vertical.gutter--#{$gutter-name} {
    margin-bottom: -$gutter-size;

    > .col {
      padding-bottom: $gutter-size;
    }
  }
}

// Responsive Gutter

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @each $gutter-name, $gutter-size in $grid-gutter-extra {
      .row.gutter--#{$gutter-name}--#{$breakpoint-name} {
        margin-left: math.div($gutter-size, -2);
        margin-right: math.div($gutter-size, -2);

        > .col {
          padding-left: $gutter-size * 0.5;
          padding-right: $gutter-size * 0.5;
        }
      }
      .row.gutter--vertical.gutter--#{$gutter-name}--#{$breakpoint-name} {
        margin-bottom: -$gutter-size;

        > .col {
          padding-bottom: $gutter-size;
        }
      }
    }
  }
}

.grid-container {
  padding-left: $grid-padding-mobile;
  padding-right: $grid-padding-mobile;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(sm) {
    padding-left: $grid-padding-tablet;
    padding-right: $grid-padding-tablet;
  }

  @include breakpoint(lg) {
    padding-left: $grid-padding-desktop;
    padding-right: $grid-padding-desktop;
  }

  &.contained {
    max-width: $grid-max-width;
  }

  &.flush {
    padding: 0;
  }

  &.padded {
    padding-left: $grid-padding-mobile;
    padding-right: $grid-padding-mobile;

    @include breakpoint(sm) {
      padding-left: $grid-padding-tablet;
      padding-right: $grid-padding-tablet;
    }

    @include breakpoint(lg) {
      padding-left: $grid-padding-desktop;
      padding-right: $grid-padding-desktop;
    }
  }
}

// Responsive Container
@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .grid-container.contained--#{$breakpoint-name} {
      max-width: $grid-max-width;
    }
    .grid-container.flush--#{$breakpoint-name} {
      padding: 0;
    }
    .grid-container.padded--#{$breakpoint-name} {
      padding-left: $grid-padding-mobile;
      padding-right: $grid-padding-mobile;

      @include breakpoint(sm) {
        padding-left: $grid-padding-tablet;
        padding-right: $grid-padding-tablet;
      }

      @include breakpoint(lg) {
        padding-left: $grid-padding-desktop;
        padding-right: $grid-padding-desktop;
      }
    }
  }
}

.grid-container-reset {
  margin-left: -$grid-padding-mobile;
  margin-right: -$grid-padding-mobile;

  @include breakpoint(sm) {
    margin-left: -$grid-padding-tablet;
    margin-right: -$grid-padding-tablet;
  }

  @include breakpoint(lg) {
    margin-left: -$grid-padding-desktop;
    margin-right: -$grid-padding-desktop;
  }
}

// Alignment
.row.align--right {
  text-align: right;

  > .col {
    text-align: left;
  }
}

.row.align--center {
  text-align: center;

  > .col {
    text-align: left;
  }
}

.row.align--middle {
  > .col {
    vertical-align: middle;
  }
}

.row.align--top {
  > .col {
    vertical-align: top;
  }
}

.row.align--bottom {
  > .col {
    vertical-align: bottom;
  }
}

.row.align--baseline {
  > .col {
    vertical-align: baseline;
  }
}

// Column Direction
.row.direction--rev {
  direction: rtl;
  text-align: left;

  > .col {
    direction: ltr;
    text-align: left;
  }
}

.row.direction--forward {
  direction: ltr;
  text-align: left;

  > .col {
    direction: ltr;
    text-align: left;
  }
}

@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    .row.direction--rev--#{$breakpoint-name} {
      direction: rtl;
      text-align: left;

      > .col {
        direction: ltr;
        text-align: left;
      }
    }

    .row.direction--forward--#{$breakpoint-name} {
      direction: ltr;
      text-align: left;

      > .col {
        direction: ltr;
        text-align: left;
      }
    }
  }
}

// Column Sizes
@for $i from 0 through $column-size {
  .col.c#{$i} {
    width: math.div(100%, $column-size) * $i;
  }
}

// Responsive Column Sizes
@each $breakpoint-name, $breakpoint-size in $breakpoints {
  @media (min-width: #{$breakpoint-size}) {
    @for $i from 0 through $column-size {
      .col.c#{$i}--#{$breakpoint-name} {
        width: math.div(100%, $column-size) * $i;
      }
    }
  }
}
