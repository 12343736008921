%input {
  display: inline-block;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  appearance: none;

  &:focus,
  &.focus {
    outline: none;
  }

  &.full-width {
    display: block;
    width: 100%;
  }

  &.no-focus {
    box-shadow: none;
  }

  @include placeholder {
    color: color(gray-text);
  }
}

input {
  @extend %input;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}

.input--error {
  box-shadow: 0 0 0 1px color(red);
}

/*
// === Primary ===
*/

.input--primary {
  @extend %input;
  font-size: 15px;
  font-family: $font-family-franklin-condensed;
  background: color(gray-bg);
  color: color(blue);
  height: $input-height;
  padding: 1px 14px 0;

  @include breakpoint(lg) {
    height: $input-height-desktop;
  }

  &.error {
    box-shadow: inset 0 0 0 1px color(red);
  }

  &:focus,
  &.focus {
    background: color(white);
    box-shadow: inset 0 0 0 1px color(blue);

    &.no-focus {
      box-shadow: none;
    }
  }
  /* if mobile, use font-size 16px for no iOS input zoom */
  @include breakpoint-max(lg) {
    font-size: 16px;
  }
}

textarea.input--primary {
  resize: vertical;
  width: 100%;
  min-height: 150px;
  line-height: 16px;
  padding: 14px;
}

.input--secondary {
  @extend %input;
  font-size: 15px;
  font-family: $font-family-franklin-condensed;
  background: color(white);
  color: color(blue);
  height: $input-height;
  line-height: $input-height;
  padding: 0 14px;

  @include breakpoint(lg) {
    height: $input-height-desktop;
    line-height: $input-height-desktop;
  }

  &.error {
    box-shadow: inset 0 0 0 1px color(red);
  }

  &:focus,
  &.focus {
    box-shadow: inset 0 0 0 1px color(blue);

    &.no-focus {
      box-shadow: none;
    }
  }
  /* if mobile, use font-size 16px for no iOS input zoom */
  @include breakpoint-max(lg) {
    font-size: 16px;
  }
}

label {
  display: inline-block;
  font-size: 14px;
  font-family: $font-family-franklin-condensed;
  color: color(blue);
  cursor: pointer;
  line-height: 1.2;
}

.input--secondary--gray-bg {
  @extend %input;
  @include body-font-size;
  font-family: $font-family-franklin-condensed;
  background: color(gray-bg);
  color: color(black);
  height: $button-height;
  padding: 0 14px;

  &.error {
    box-shadow: inset 0 0 0 1px color(red);
  }

  &:focus,
  &.focus {
    box-shadow: inset 0 0 0 1px color(blue);

    &.no-focus {
      box-shadow: none;
    }
  }
  /* if mobile, use font-size 16px for no iOS input zoom */
  @include breakpoint-max(lg) {
    font-size: 16px;
  }
}

.input__row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.input--checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid color(blue);
  vertical-align: -3px;

  &:checked + .input--checkbox__indicator {
    display: block;
  }
}

.input--checkbox__indicator {
  display: none;
  background-image: url('~assets/images/shared/icon-checkmark.svg');
  background-size: 17px 13px;
  background-repeat: no-repeat;
  background-position: center;
  width: 17px;
  height: 13px;
  position: absolute;
  left: 3px;
  top: 1px;
  pointer-events: none;
}

.input--radio {
  width: 16px;
  height: 16px;
  border: 2px solid color(blue);
  border-radius: 50%;
  vertical-align: -3px;

  &:checked {
    background-color: color(yellow);
    box-shadow: inset 0 0 0 2px color(white);
  }
}

.input--select__container {
  position: relative;

  &:after {
    display: block;
    content: '';
    background-image: url('~assets/images/shared/icon-select.svg');
    background-size: 13px 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: lighten(color(gray-bg), 2.5%);
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 49px;
    pointer-events: none;
  }
}

.input__placeholder-label {
  position: absolute;
  left: 12px;
  top: -6px;
  background: color(white);
  display: inline-block;
  font-size: 10px;
  line-height: 10px;
  font-family: $font-family-franklin-condensed;
  color: color(gray-text-light);
  padding: 2px 4px;
}

.input__valid-check {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: block;
}

.input--select__valid-check {
  right: 65px;
}
