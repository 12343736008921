//
// === UI ===
//

.ui--fullscreen {
  height: 100%;

  body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .App {
    height: 100%;
  }
}
