.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 40px 10px 10px 10px;
}

.modal-content {
  position: relative;
  background: color(gray-bg);
  background-clip: padding-box;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.modal-header {
  padding: 15px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;

  h4 {
    font-weight: bold;
  }
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-close-button {
  color: color(white);

  position: absolute;
  top: -30px;
  right: 0;
  padding-bottom: 5px;

  font-size: 40px;

  background: transparent;
  border: 0;

  transform: rotate(45deg);

  text-shadow: 1px 1px 10px black;

  outline: none;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 80px auto 30px;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: 300px;
  }

  .modal-body {
    padding: 2em 4em;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: ' ';
}

.modal-header:after,
.modal-footer:after {
  clear: both;
}
