%button {
  @include body-font-size;
  color: color(black);
  font-family: inherit;
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  appearance: none;
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &.loading {
    cursor: wait;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &.loading {
      cursor: wait;
    }
  }

  &.full-width {
    display: block;
    width: 100%;
    min-width: initial;
  }
}

%buttonstyle {
  height: $button-height;
  line-height: $button-height - 4px;
  background: color(blue);
  color: white;
  font-family: $font-family-franklin-condensed;
  font-size: $button-font-size;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 3px 20px 1px;
  min-width: 160px;

  &.flush-width {
    min-width: initial;
  }

  &.button--icon {
    padding-left: 0;
  }
}

button {
  @extend %button;
}

.button__icon-container {
  width: 50px;
  margin-top: -2px;
}

.button--facebook {
  @extend %button;
  @extend %buttonstyle;
  background: #3b5998;

  &:focus,
  &:hover {
    background: rgba(#3b5998, 0.95);
  }

  &:active {
    background: darken(#3b5998, 5%);
  }
}

.button--google {
  @extend %button;
  @extend %buttonstyle;
  background: #5284ec;

  &:focus,
  &:hover {
    background: rgba(#5284ec, 0.95);
  }

  &:active {
    background: darken(#5284ec, 5%);
  }
}

.button--paypal {
  @extend %button;
  @extend %buttonstyle;
  line-height: $button-height - 8px;
  background: #199dda;
  padding: 12px 20px 1px;

  img {
    width: 80px;
  }

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }
}

.button--primary {
  @extend %button;
  @extend %buttonstyle;

  &:focus,
  &:hover {
    background: #2f3e4c;
  }

  &:active {
    background: #1c232b;
  }

  &:disabled {
    background: #a8b1b9;
  }
}

.button--primary--yellow {
  @extend %button;
  @extend %buttonstyle;
  background: color(yellow);

  &:focus,
  &:hover,
  &:active {
    background: darken(color(yellow), 5%);
  }

  &:disabled {
    background: #a8b1b9;
  }
}

.button--f4 {
  font-size: 16px;
}

.button--primary--invert {
  @extend %button;
  @extend %buttonstyle;
  color: color(blue);
  background: color(white);

  &:focus,
  &:hover {
    background: rgba(color(blue), 0.95);
    color: color(white);
  }

  &:active {
    background: #1c232b;
    color: color(white);
  }

  &:disabled {
    background: color(white);
    color: color(blue);
  }
}

.button--primary--gray-bg {
  @extend %button;
  @extend %buttonstyle;
  color: color(blue);
  background: color(gray-bg);

  &:focus,
  &:hover {
    background: rgba(color(gray-bg), 0.95);
  }

  &:active {
    background: rgba(color(gray-bg), 0.95);
  }
}

.button--primary--orange {
  @extend %button;
  @extend %buttonstyle;
  background: color(red-orange);
  color: color(white);

  &:focus,
  &:hover,
  &:active {
    background: darken(color(red-orange), 5%);
  }

  &:disabled {
    background: #a8b1b9;
  }
}

.button--secondary {
  @extend %button;
  @extend %buttonstyle;
  line-height: $button-height - 8px;
  background: none;
  color: color(blue);
  border: 2px solid color(blue);

  &:focus,
  &:hover {
    background: rgba(color(blue), 0.95);
    color: color(white);
  }

  &:active {
    background: #1c232b;
    color: color(white);
  }

  &:disabled {
    border-color: #a8b1b9;
    color: #a8b1b9;
  }
}

.button--tab--hover {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(blue);
  text-transform: uppercase;

  &:hover,
  &:focus {
    border-bottom: 2px solid color(gray-bg-dark);
  }

  &.active {
    border-bottom: 2px solid color(blue);
  }

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--tab--invert {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(white);
  text-transform: uppercase;
  border-bottom: 2px solid white;

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--tab--nudge {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(blue);
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    border-bottom: 2px solid;
    pointer-events: none;
    transition: transform 100ms ease-in-out;
  }

  &:hover:after {
    transform: translateY(2px);
  }

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--tab--nudge-invert {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(white);
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    border-bottom: 2px solid;
    pointer-events: none;
    transition: transform 100ms ease-in-out;
  }

  &:hover:after {
    transform: translateY(2px);
  }

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--tab--hover {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(blue);
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition: border-color 200ms ease;

  &:hover,
  &:focus {
    border-color: color(gray-bg-dark);
  }

  &.active {
    border-color: color(blue);
  }

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--tab--invert {
  @extend %button;
  font-size: 13px;
  line-height: 16px;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: color(white);
  text-transform: uppercase;
  border-bottom: 2px solid white;

  @include breakpoint(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

//
// === Tiles ===
//

.button--tile__container {
  .button--tile--small {
    margin-left: -1px;
  }
}

.button--tile__container--vertical {
  .button--tile--thumb {
    margin-top: -1px;
  }
}

.button--tile--small {
  @extend %button;
  background: color(white);
  color: color(blue);
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 1px solid color(gray-bg-dark);
    opacity: 0;
    transition: opacity 200ms ease, border-color 200ms ease;
    pointer-events: none;
  }

  &:hover:before,
  &:focus:before {
    opacity: 1;
  }

  &.active:before {
    opacity: 1;
    border-color: color(blue);
    z-index: 10;
  }

  &.disabled,
  &:disabled {
    color: color(gray-bg-dark);

    &:before {
      display: none;
    }
  }

  &.unavailable {
    color: color(gray-bg-dark);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      transition: opacity 200ms ease, border-color 200ms ease;
      pointer-events: none;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('~assets/images/shared/icon-unavailable-variant.svg');
    }
  }

  &.read-only {
    cursor: text;

    &:before {
      display: none;
    }
  }

  .button--tile--small__swatch-color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.button--tile--round {
  @extend %button;

  background: color(white);
  border-radius: 20px;
  border: 2px solid color(gray-bg);
  line-height: 24px;
  letter-spacing: 0.03em;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 15px;
  display: block;
}

.button--tile--quantity {
  @extend %button;
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-family: $font-family-franklin-condensed;
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
  position: relative;
  border: 1px solid color(gray-border);
  color: color(gray-text-light);

  &:hover,
  &:focus {
    color: color(blue);
    border-color: color(blue);

    &.disabled,
    &:disabled {
      border-color: color(gray-border);
      color: color(gray-text-light);
    }
  }
}

.button--tile--quantity--small {
  @extend %button;
  height: 16px;
  line-height: 16px;
  min-width: 16px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-family: $font-family-franklin-condensed;
  font-weight: 400;
  font-size: 12.64px;
  padding: 0;
  position: relative;
  color: color(twilight-blue);
  background-color: color(gray-bg);

  &:hover,
  &:focus {
    color: color(blue);
    border-color: color(blue);

    &.disabled,
    &:disabled {
      border-color: color(gray-border);
      color: color(gray-text-light);
    }
  }

  @include breakpoint-max(lg) {
    height: 24px;
    line-height: 24px;
    min-width: 24px;
  }
}

.button--tile--thumb {
  @extend %button;
  background: color(gray-bg);
  height: 80px;
  line-height: 80px;
  min-width: 80px;
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  will-change: opacity;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: 1px solid color(gray-bg-dark);
    opacity: 0;
    transition: opacity 200ms ease, border-color 200ms ease;
    pointer-events: none;
  }

  &:hover:before,
  &:focus:before {
    opacity: 1;
  }

  &.active:before {
    opacity: 1;
    border-color: color(blue);
    z-index: 10;
  }

  @include breakpoint(lg) {
    height: 70px;
    line-height: 70px;
    min-width: 70px;
  }
}

.button--select-variant {
  @extend %button;
  background: color(gray-bg);
  height: 38px;
  line-height: 30px;
  min-width: 170px;
  padding: 3px;
  border: 1px solid color(blue);
}

.button--select-variant__arrow {
  width: 30px;
  height: 30px;

  &.active {
    transform: scaleY(-1);
  }
}

.button--select-variant__value {
  height: 30px;
  line-height: 30px;
}

.button--select-variant__option-container {
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
  z-index: $tooltip-z-index;
  background: color(gray-bg);
  min-width: 170px;
  padding: 6px;
  border: 1px solid color(blue);
  max-height: 215px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.button--select-variant__option {
  padding: 4px;

  &:hover {
    background: color(white);
  }

  &:disabled:hover {
    background: none;
  }
}
