%primary-button-preset {
  @extend %primary-button-and-link-preset;
  transition: background-color 200ms ease-out;
  padding: $button-padding;
}

.primary-button-preset-1 {
  @extend %primary-button-preset;
  @extend .color-secondary;
  @extend .bg-color-primary;

  &:hover {
    background-color: color(twilight-blue-faded);
  }
}

.primary-button-preset-2 {
  @extend %primary-button-preset;
  @extend .color-primary;
  @extend .bg-color-secondary;

  // to make sure content is aligned on the center of the button
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;

  &:hover {
    background-color: color(dark-yellow);
  }

  // temporary preset to add styling to paypal button
  &.paypal {
    background-color: color(paypal-blue);

    &:hover {
      background-color: darken(color(paypal-blue), 10);
    }
  }
}

.primary-button-preset-3 {
  @extend %primary-button-preset;
  @extend .color-primary;
  @extend .bg-color-tertiary;

  &.small-font {
    @extend .primary-text-preset-9;
    padding: 16px 8px;
  }
}

.primary-button-preset-disabled {
  @extend .bg-color-disabled;
  color: color(blue);

  // retain bg color when button is disabled
  &:hover {
    cursor: not-allowed;
    background-color: color(grayish-blue);
  }
}

%secondary-button-preset {
  @extend .bg-color-tertiary;
  @extend .color-primary;
  @extend .uppercase;
  @extend .font-secondary;
  display: flex;
  min-height: 32px;
  min-width: 32px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid color(gray-border);
  flex: 0 0 calc(20% - 6.5px);
  transition: border-color 100ms ease-in;

  &:hover,
  &:focus,
  &.active {
    border-color: color(twilight-blue);
  }

  &.wide {
    flex: 0 0 auto;
    background: transparent;
    pointer-events: none;
  }

  @media screen and (max-width: 1300px) {
    flex: 0 0 calc(25% - 6px);
  }

  @include breakpoint-max(lg) {
    padding: 0 8px;
    height: 40px;
    min-width: 40px;
  }
}

.secondary-button-preset-1 {
  @extend %secondary-button-preset;
  @extend .text-sm-no-break-point;
  position: relative;
  overflow: hidden;
}

.secondary-button-preset-2 {
  @extend %secondary-button-preset;
  @extend .text-xs;
  position: relative;
  overflow: hidden;
}

.secondary-button-preset-disabled {
  color: color(gray-bg-dark);
  &:after {
    content: '';
    background-color: color(gray-border);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: translateY(-50%) rotate(45deg);
  }
}

.badge-preset {
  @extend .bg-color-tertiary;
  @extend .color-primary;
  @extend .uppercase-new;
  @extend .secondary-text-preset-4;
  padding: 6px 8px;

  @include breakpoint-max(lg) {
    padding: 4px 6px;
  }
}
