@import '~styles/global/config';

.app-container {
  .ProductTile {
    vertical-align: top;
    &:last-child {
      margin-right: 0;
    }
    &:hover .ProductTile__title {
      text-decoration: underline;
    }
    @include breakpoint-max(lg) {
      margin-right: 0;
    }
    .block__subheader--sm {
      font-weight: 600;
    }
    .NewFeatureOverlay__message-wrapper {
      width: 180px;

      @include breakpoint(lg) {
        width: 300px;
      }
    }
  }
  .ProductTile__card .YotpoStars {
    position: absolute;
    bottom: 12px;
    left: 12px;
    @include breakpoint-max(lg) {
      bottom: 8px;
      left: 8px;
      .YotpoStars__star {
        margin-right: 3px;
      }
    }
  }

  .ProductTile--slider {
    width: 150px;
    display: inline-block;
    @include breakpoint(sm) {
      width: 200px;
    }
  }
  .ProductTile__favorite-btn {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 12px;
    right: 12px;

    @include breakpoint-max(lg) {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 8px;
    }

    .NewFeatureOverlay__message-wrapper {
      @include breakpoint-max(lg) {
        position: absolute;
        top: -84px;
        right: 50%;
        z-index: 1;
        width: calc((100vw - 49px) / 2);
        margin-right: -22px;
      }

      @include breakpoint-max(sm) {
        width: calc(100vw - 40px);
        margin-right: calc((100vw + 13px) / 2 * -1);
      }
    }

    .NewFeatureOverlay {
      .EventMessage__popup-arrow {
        @include breakpoint-max(lg) {
          transform: rotate(180deg);
          top: 68px;
          right: 13.5px;
        }

        @include breakpoint-max(sm) {
          margin-right: calc((100vw - 33px) / 2);
        }
      }
    }
  }
}
