// Global styles to unset attributes defined in Bootstrap which conflict with
// styles defined in components.

// This should be removed when everything is rendered by the react on-page
// layout which doesn't include bootstrap.

.media {
  zoom: auto;
  overflow: visible;
}

.media,
.media:first-child {
  margin-top: 0;
}
