// Configuration
@import 'global/config';
@import 'global/mixins';

@import 'global/fonts';

.app-container--constrained {
  @include breakpoint-max(lg) {
    overflow-x: hidden;
  }
}
.app-container--hide-overflow-x {
  overflow-x: hidden;
}

.app-container {
  // Globals
  @import 'global/reset';
  @import 'global/utilities';
  @import 'global/typography';
  @import 'global/icons';
  @import 'global/typography-new';

  // Components
  @import 'components/grid';
  @import 'components/list';
  @import 'components/media';
  @import 'components/spacer';
  @import 'components/visibility';
  @import 'components/button';
  @import 'components/input';
  @import 'components/deal-tag';
  @import 'components/video-embed';
  @import 'global/bootstrap-unset';
  @import 'components/bootstrap';
  @import 'components/link';
  @import 'components/button-new';
}

@import 'global/fullscreen';
@import 'global/swiper';

@import 'components/Pages/Tiles/TilesContainer/ProductTile/index.scss';
@import 'components/Pages/Tiles/Filters/Shared/FilterDrawer.scss';
